<!-- -A设备管理-备件管理-备件盘点-添加盘点单v -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">盘点信息</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="仓库：">
                    <!-- <el-input v-model="formInline.warehouse" placeholder="请输入" class="width_270 mg_right_50"></el-input> -->
                    <el-select v-model="formInline.warehouse" clearable placeholder="请选择仓库">
                        <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="仓库：">
                    <el-select v-model="formInline.region" placeholder="" class="width_180 mg_right_50">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label="经办人：">
                    <el-select v-model="formInline.region" placeholder="" class="width_180 mg_right_50">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" label-width="110px" class="flex flex_wrap " style="width: 65%;">
                <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">备件信息</div>
            <!-- 按钮 -->
            <div class="flex mg_top_10" style="width: 65%;">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="selectSparePart">选择备件</el-button>
                <!-- <el-button type="primary" icon="el-icon-circle-plus-outline">删除</el-button> -->
            </div>
            <!-- table -->
            <div class="" style="width: 65%;">
                <el-form :model="formData" ref="formData" class="mg_top_15 height_300">
                    <el-table :data="formData.tableData" border stripe style="width: 100%;" height="100%">
                        <el-table-column type="index" label="序号" width="70">
                        </el-table-column>
                        <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="insuredQuan" label="账面数量" show-overflow-tooltip>
                            <!-- <template slot-scope="scope">
                            读
                            <el-input v-model="scope.row.insuredQuan"></el-input>
                        </template> -->
                        </el-table-column>
                        <el-table-column prop="realQuan" label="实际数量">
                            <template slot-scope="scope">
                                <el-form-item :prop="'tableData.' + scope.$index + '.realQuan'"
                                    style="margin-bottom: 0px;">
                                    <el-input v-model="scope.row.realQuan" placeholder="请输入" type="number"
                                        @input="changeInput(scope.$index)"></el-input>
                                </el-form-item>
                                <!-- <span v-else>{{scope.row.realQuan}}</span> -->
                            </template>
                        </el-table-column>
                        <el-table-column prop="profitQuan" label="盘盈数量">
                            <template slot-scope="scope">
                                <el-form-item :prop="'tableData.' + scope.$index + '.profitQuan'"
                                    style="margin-bottom: 0px;">
                                    <el-input v-model="scope.row.profitQuan" placeholder="请输入" readonly></el-input>
                                </el-form-item>
                                <!-- <span v-else>{{scope.row.profitQuan}}</span> -->
                            </template>
                        </el-table-column>
                        <el-table-column prop="lossQuan" label="盘亏数量">
                            <template slot-scope="scope">
                                <el-form-item :prop="'tableData.' + scope.$index + '.lossQuan'"
                                    style="margin-bottom: 0px;">
                                    <el-input v-model="scope.row.lossQuan" placeholder="请输入" readonly></el-input>
                                </el-form-item>
                                <!-- <span v-else>{{scope.row.lossQuan}}</span> -->
                            </template>
                        </el-table-column>
                        <el-table-column prop="remarks" label="备注">
                            <template slot-scope="scope">
                                <el-form-item :prop="'tableData.' + scope.$index + '.remarks'"
                                    style="margin-bottom: 0px;">
                                    <el-input v-model="scope.row.remarks" placeholder="请输入"></el-input>
                                </el-form-item>
                                <!-- <span v-else>{{scope.row.remarks}}</span> -->
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pd_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="delStandard(scope.$index)">删除</span>
                                </el-button>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column prop="sex" label="性别">
                        <template slot-scope="scope">
                            <el-form-item v-if="scope.row.edit" :prop="'tableData.' + scope.$index + '.sex'">
                                <el-select v-model="scope.row.sex" placeholder="请选择">
                                    <el-option label="男" value="1">男</el-option>
                                    <el-option label="女" value="2">女</el-option>
                                </el-select>
                            </el-form-item>
                            <span v-else>
                                <p v-if="scope.row.sex==1">男</p>
                                <p v-if="scope.row.sex==2">女</p>
                            </span>
                        </template>
                    </el-table-column> -->
                        <!-- <el-table-column label="操作">
                    </el-table-column> -->
                    </el-table>
                </el-form>
                <!-- <el-table :data="tableData" tooltip-effect="dark" class="mg_top_15 " height="100%" border
                    style="width: 100%;">
                    <el-table-column type="index" label="序号" width="70">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="insuredQuan" label="账面数量insuredQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            读
                            <el-input v-model="scope.row.insuredQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="realQuan" label="实际数量realQuan">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.realQuan" @change="changeValue"></el-input>
                        </template>
                    </el-table-column> -->
                <!-- 盘盈数量：realQuan>insuredQuan r-I；R<I： 0 -->
                <!-- <el-table-column prop="profitQuan" label="盘盈数量profitQuan">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.realQuan" readonly></el-input>
                        </template>
                    </el-table-column> -->
                <!-- 盘亏数量  R>I 0  R<I： r-I -->
                <!-- <el-table-column prop="lossQuan" label="盘亏数量lossQuan">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.lossQuan" readonly>1111</el-input>
                        </template>
                    </el-table-column> -->
                <!-- <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.remarks"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delStandard(scope.$index)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table> -->
            </div>


            <div class="flex mg_top_30 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>

        <el-dialog title="选择备件台账":visible.sync="sparePartDialog" top='15vh'>
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">选择备件台账</div> -->
            <div class="mg_top_10  fn_size14 color_1A1A1A height_500">
                <!-- <div v-for="(item,index) in inspectionStandardInfoList.inspDeviceContent" :key="index"> -->
                <!-- <div class="flex font_bold mg_top_10">
                        <span>点检标准编号：</span>
                        <span class="color_007DF2">{{item.inspStandardID}}</span>
                    </div> -->
                <el-table ref="multipleTable" :data="sparePartList" style="width: 100%;" height="100%" class="mg_top_10"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="70">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="stockQuan" label="账面数量" show-overflow-tooltip>
                    </el-table-column>
                    <!-- <el-table-column prop="insuredQuan" label="insuredQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.insuredQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="realQuan" label="realQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.realQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="profitQuan" label="profitQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.profitQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lossQuan" label="lossQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.lossQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.remarks"></el-input>
                        </template>
                    </el-table-column> -->
                </el-table>

            </div>
            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            <div class="flex justify_center pdb_20 mg_top_20">
                <el-button @click="sparePartDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSparePart">确 定</el-button>
            </div>
        </el-dialog>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        sparePartStockQueryLimit,
        sparePartInventoryInsert,
        sparePartWarehouseQuery,// 仓库选择下拉框
        // inspectionOrderUpdate,
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                checked: false,
                tableData: [],
                formInline: {},
                input2: '',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                userID: '',
                sparePartDialog: false,
                sparePartList: [],
                formData: {
                    tableData: []
                },
                warehouseSelectList:[],//仓库下拉框 使用的数组
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                // console.log(userInfo)
                this.userID = userInfo.userID;
                this.userName = userInfo.userName;
            }

            //页面一加载，就初始化仓库下拉框的数据
            this.sparePartWarehouseQuery();
            // this.sparePartStockQueryLimit()
        },
        methods: {

            //仓库下拉框
            async sparePartWarehouseQuery(){

                //封装参数
                var param = {
                    userID:this.userID,
                };

                //调用接口
                const selectRes = await sparePartWarehouseQuery(param);

                //处理返回值
                if(selectRes){//如果有返回值
                    this.warehouseSelectList = selectRes.data;
                }
            },

            changeInput(index) {
                console.log(this.formData)
                console.log(this.formData.tableData[index])
                if (this.formData.tableData[index].realQuan > this.formData.tableData[index].insuredQuan) {
                    this.formData.tableData[index].profitQuan = Math.abs(this.formData.tableData[index].realQuan - this
                        .formData.tableData[index].insuredQuan);
                    this.formData.tableData[index].lossQuan = 0
                } else {
                    this.formData.tableData[index].lossQuan = Math.abs(this.formData.tableData[index].realQuan - this
                        .formData.tableData[index].insuredQuan);
                    this.formData.tableData[index].profitQuan = 0;
                }
                //  profitQuan  盘盈数量：realQuan>insuredQuan r-I；R<I： 0
                // lossQuan 盘亏数量  R>I 0  R<I： r-I 
                // this.formData.tableData[index].lossQuan = 666;
                // console.log(this.formData.tableData[index])
            },

            // 选择备件--弹窗--开始
            async sparePartStockQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                const res = await sparePartStockQueryLimit(param);
                this.sparePartList = res.rows;
                this.pageQuantity = res.pageQuantity;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                // console.log(this.multipleSelection)
            },
            selectSparePart() {
                // this.$nextTick(() => {
                //     if (this.$refs['multipleTable']) {
                //         this.$refs['multipleTable'].clearSelection();
                //     }
                // });
                this.sparePartStockQueryLimit()
                this.sparePartDialog = true;
            },
            sureSparePart() {
                // console.log(this.sparePartList);
                // var testData = this.tableData.filter(itmer => itmer.sparePartID == this.currentRow.sparePartID);
                // // console.log(testData)
                // if (testData.length != 0) {
                //     this.$message({
                //         message: '警告，此设备已添加',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.tableData = [];
                this.formData.tableData = [];
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    var testData = {
                        sparePartID: '', //备件编号
                        sparePartName: '', //备件名称
                        sparePartModel: '', //备件型号
                        unit: '', //单位
                        insuredQuan: '', //
                        realQuan: '',
                        profitQuan: '',
                        lossQuan: ''
                        // "realQuan": 90,
                        // "profitQuan": 0,
                        // "lossQuan": 10,
                    }
                    testData.sparePartID = this.multipleSelection[i].sparePartID;
                    testData.sparePartName = this.multipleSelection[i].sparePartName;
                    testData.sparePartModel = this.multipleSelection[i].sparePartModel;
                    testData.unit = this.multipleSelection[i].unit;
                    testData.insuredQuan = this.multipleSelection[i].stockQuan;
                    // this.tableData.push(testData);
                    this.formData.tableData.push(testData);
                    // this.formData.tableData.push({
                    //     edit: true,
                    // });
                }
                this.sparePartDialog = false;
                this.multipleSelection = [];

                // console.log(this.formData)
                // sparePartID
            },
            handleCurrentChange(val) {
                this.sparePartStockQueryLimit(); //查询table数据的方法
            },
            //  // 选择备件--弹窗--结束

            changeValue(val) {
                console.log(val)
            },

            delStandard(index) {
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(index)
                    this.formData.tableData.splice(index, 1)
                    console.log(this.formData.tableData);
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            async sbumit() {
                for (var i = 0; i < this.formData.tableData.length; i++) {
                    if (!this.formData.tableData[i].realQuan) {
                        this.$message({
                            message: '警告哦，“' + this.formData.tableData[i].sparePartName + '”请填写实际数量',
                            type: 'warning'
                        });
                        return
                    }
                }
                this.formInline.userID = this.userID;
                var param = {
                    array: []
                }
                // console.log()
                if (this.formData.tableData.length != 0) {
                    param.array = this.formData.tableData
                    this.formInline.orderDetailJSON = param;
                }
                console.log(this.formInline)
                const selectRes = await sparePartInventoryInsert(this.formInline);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            colsePage() {
                this.$router.go(-1);
            },
            //table选择

        }

    }
</script>
<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }
</style>